import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`About Us`}</h1>
    <p>{`We are a small team of developers who take passion and enjoyment in designing websites and small scale web applications.
If you are a small business owner or running the local boutique shop we are sure we got your web designing needs covered.
We also use 100% open source technologies which means you will have the full control of your website. Feel free to `}<a parentName="p" {...{
        "href": "#contact"
      }}>{`get in touch`}</a>{`
with us and we'll be excited to work with you.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      