import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Projects`}</h1>
    <ProjectCard title="OpenLearnr" thumbnailPath="https://raw.githubusercontent.com/OpenArchitex/openarchitex.dev/main/src/images/pythonsinhala.png" link="https://www.pythonsinhala.com/" bg="grey" textColor="white" mdxType="ProjectCard">
  A course management system for an online tutor. Students can log in and watch video lectures and the instructor can upload lectures study materials.
    </ProjectCard>
    <ProjectCard title="CherishCakes" thumbnailPath="https://raw.githubusercontent.com/OpenArchitex/openarchitex.dev/main/src/images/cherishcakes.png" link="https://cherishcakes.ca/" bg="#00A6FF" textColor="white" mdxType="ProjectCard">
   Simple website for a small business owner who makes custom cakes. The website includes details about custom cakes, how to order and a
   gallery full of past creations.
    </ProjectCard>
    <ProjectCard title="TharshannaPortfolio" thumbnailPath="https://raw.githubusercontent.com/OpenArchitex/openarchitex.dev/main/src/images/tharshannaportfolio.png" link="https://www.tharshanna.ca/" bg="#95FFA7" textColor="white" mdxType="ProjectCard">
    Portfolio website for Tharshanna Nadarajah; a professor at the University of Toronto
    </ProjectCard>
    <ProjectCard title="FaithPortfolio" thumbnailPath="https://raw.githubusercontent.com/OpenArchitex/openarchitex.dev/main/src/images/faithportfolio.png" link="https://www.faithlee.ca/" bg="#FFA7A2" textColor="white" mdxType="ProjectCard">
    Portfolio website for Faith Lee; a PhD student at Université Laval
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      